<!-- 安全中心--设置密码--身份认证 第一步-->
<template>
  <div class="idverify">
    <div class="top">
      <el-row class='head'>
        <el-col :span="12">
          <logotitle :title='["设置支付密码","修改支付密码","修改登录密码","找回密码"][this._spf(this.$route.query.types)]'></logotitle>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
    </div>
    <div class="stepcnt">
      <verifystep class='verifystep' :steps='0'></verifystep>
      <div class="verifybox">
        <div class="icon">
          <span><svg><use xlink:href="#iconshouji1"></use></svg></span>
          <h6>手机短信验证码</h6>
        </div>
        <div class="phone" v-if='phone'>
          当前手机号{{phone| formatPhoneHide(1)}}
        </div>
        <div class="phone phoneipt" v-if='!phone'>
          <input type="text" placeholder='当前手机号' v-model='phoneipt'>
          <p class="errp" v-if='this.iptphones'>{{this.iptphones}}</p>
        </div>
        <div class="phonesedcode">
          <div class="lt">
            <input type="text" placeholder='验证码' v-model='codes'>
            <p class="p" v-show='!codeerr&&codeerr!=null'>验证码已发送至您的手机，10分钟内有效</p>
            <p class="errp" v-show='codeerr'>{{codeerr}}</p>
          </div>
          <div class="rt">
            <getcodebtn :initPhone='phone?phone:phoneipt' :types='1' @exportErr='phoneError' @exportpass='phonepass' class='btn'></getcodebtn>
          </div>
        </div>
        <button @click='nexts' class="next" >下一步</button>
        <div class="qainfo">
          <div class="infos">
            <span>Q</span>
            <P>收不到短信验证码？</P>
          </div>
          <div class="infos">
            <span>A</span>
            <p>请检查手机网络并且核实手机是否屏蔽系统短信，如均正常请重新或稍后再试。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import verifystep from '@/components/verifystep/verifystep.vue';//图片标题组件
import getcodebtn from '@/components/getcodebtn/getcodebtn.vue';//获取验证码按钮组件-types属性切换接口
import {mixin,setOrGetCode} from '../assets/js/common.js' //公用js
import md5 from 'js-md5';
export default {
  name: 'idverify',
  mixins: [mixin],//混入
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'安全中心-微微定',
      // phone:'18752360925',
      phone:this.$store.state.user&&this.$store.state.user.Mobile?this.$store.state.user.Mobile:this._spf(this.$route.query.phone),
      codeerr:null,//错误信息
      codes:null,//验证码
      phoneipt:null,
      iptphones:'',//手机号错误
    }
  },
  components: {
    logotitle,
    verifystep,
    getcodebtn
  },
  methods:{
    phoneError(v){
      // 手机号错误
      // this.errorData.phonetxt = v
      // this.phonereg = true
      // this.phone=''
      // console.log(v)
      this.iptphones = v
    },
    phonepass(){
      // 手机号通过
      // this.phonetxt = '请输入手机号码'
      // this.phonereg = true
      // var _data = {
      //   code:1,
      //   time:Date.now()
      // }
      // window.sessionStorage.setItem('phonecode',JSON.stringify(_data))
      // this.codeerr = false
      this.iptphones = ''
    },
    nexts(){
      // 下一步验证，验证码
      var _flag = true
      this.codeerr = ''
      if(!this.codes){
        return this.codeerr = '请填写验证码！'
      }
      if(setOrGetCode({method:'get'})&&md5(this.codes).toLocaleUpperCase()==setOrGetCode({method:'get'})){
        _flag = true
      }else{
        _flag = false
        this.codeerr = '验证码错误'
      }
      if(_flag){
        if(this._spf(this.$route.query.types)==3){
          this._router({then:()=>{
            this.$router.push('/verifysetpas?phone='+this._spf(this.$route.query.phone)+'&types='+this._spf(this.$route.query.types)+'.html')
          }})
        }else{
          this._router({then:()=>{
            this.$router.push('/verifysetpas?types='+this._spf(this.$route.query.types)+'.html')
          },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
          }})
        }
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
  },
  filters:{

  }
}
</script>
<style  lang="scss" scoped>
.idverify {
  padding-bottom:40px;
  .top {
    width: 100%;background: #fff;padding:10px 0px;box-shadow: 0px 3px 5px 0px rgba(212,212,212,0.4);position: relative;z-index: 9;
    .head {
      width: 1200px;margin:0 auto;
      .rechargestep {
        float:right;margin-top:5px;
      }
    }
  }
  .stepcnt {
    width: 100%;background: #fff;padding-top:25px;padding-bottom:130px;
    .verifystep {
      margin-bottom:70px;
    }
    .verifybox {
      width: 380px;margin:0 auto;
      .icon {
        span {
          display: block;width: 50px;height: 50px;background: #cccccc;border-radius: 50%;margin:0 auto;line-height: 50px;text-align: center;
          svg {
            width: 35px;height: 35px;vertical-align: -13px;fill: #fff;
          }
        }
        h6 {
          text-align: center;height: 35px;line-height: 35px;font-size: 14px;
        }
      }
      .phone {
        margin-bottom:20px;margin-top:30px;font-size: 14px;color:#3f3f40;
      }
      .phoneipt {
        position: relative;margin-bottom:30px;
        input {
          width:368px;height: 46px;border:1px solid #e8e8e8;border-radius: 5px;padding-left:10px;
          &::placeholder {
            color:#b8b8b8;
          }
        }
        .errp {
          position: absolute;width: 380px;font-size: 12px;color:#f44e55;left:0px;top:50px;
        }
      }
      .phonesedcode {
        display: flex;justify-content: space-between;padding-bottom:40px;
        .lt {
          position: relative;
          input {
            width:194px;height: 46px;border:1px solid #e8e8e8;border-radius: 5px;padding-left:10px;
            &::placeholder {
              color:#b8b8b8;
            }
          }
          .p {
            position: absolute;width: 380px;font-size: 12px;color:#a8a8a8;left:0px;top:52px;
          }
          .errp {
            position: absolute;width: 380px;font-size: 12px;color:#f44e55;left:0px;top:52px;
          }
        }
      }
      .next {
        width: 380px;height: 48px;border-radius: 5px;background: #f44e55;color:#fff;font-size: 16px;
        &:hover {
          opacity: 0.8;
        }
      }
      .qainfo {
        .infos {
          display: flex;justify-content: space-between;margin-top:20px;
          span {
            display: block;width: 20px;height: 20px;background: #61baff;line-height: 20px;text-align: center;color:#fff;font-size: 14px;border-radius: 50%;
          }
          p {
            width: 345px;color:#959595;font-size: 14px;
          }
          &:nth-child(2) {
            span {
              background: #f44e61;
            }
          }
        }
      }
    }
  }
}
</style>
